<template>
	<div class="main" v-loading="loading">
		<div v-for="(item, index) in DemoseaList" :key="index">
			<DemoSea :demoType="demoType" :DemoseaList="item" type='isBuy' gerType="none" creatType="none"></DemoSea>
		</div>
		<div class="emptyView">
			<el-empty v-if="isEmpty == true" :image-size="100"></el-empty>
		</div>
		<div class="more-wrap">
			<div v-if="(nothing == false) && (numMusician > pageSize)" class="more" @click="moreMusician">
				<Icon name="icon-7key-musicgengduoyinleren" theme-color="iconColorAll" width="16" height="16"></Icon>
				<div class="more-text">{{$t('moreMusic')}}</div>
			</div>
			<div class="nothing-text" v-if="(nothing == true) || ((numMusician < pageSize) && (numMusician > 0))">{{$t('nomore')}}</div>
		</div>
	</div>

</template>

<script>
	import DemoSea from "@/components/content/DemoSea"
	import {
		creatorMusic
	} from "@/api/Home";
	export default {
		props: {},
		components: {
			DemoSea,
		},
		data() {
			return {
				demoType: 2,
				DemoseaList: [],
				page: 1,
				pageSize: 10,
				nothing: false,
				numMusician: '',
				loading: true,
				isEmpty: false,
			}
		},
		watch: {
			page: {
				handler(newValue, oldValue) {
					console.log('page', newValue)
					if (this.page == Math.ceil(this.numMusician / this.pageSize)) {
						setTimeout(() => {
							this.nothing = true
						}, 1000)

					}
				}
			},
		},
		created() {
			this.creator_id = this.$route.query.creator_id ? this.$route.query.creator_id : localStorage.getItem(
				'creator_id');
			// let id = localStorage.getItem('creator_id')
			// console.log(this.creator_id,id,'this.creator_id')
			this.getCreatorMusic(this.creator_id); //制作人音乐列表
		},
		methods: {
			moreMusician() {
				// if(Math.ceil(this.numMusician / this.pageSize))
				// console.log(Math.ceil(this.numMusician / this.pageSize))
				console.log(this.page, Math.ceil(this.numMusician / this.pageSize), ',,,,,,,,,,')
				if (this.page < Math.ceil(this.numMusician / this.pageSize)) {
					this.page = this.page + 1
					this.getCreatorMusic(this.creator_id)
				}

			},
			// 制作人音乐列表
			getCreatorMusic(creator_id) {
				creatorMusic({
						creator_id: creator_id,
						min_bmp: '',
						max_bmp: '',
						bmp: '',
						key: '', //音调对应的键值
						Instruments: '', //仪器对应的键值
						genres: '', //体裁对应的键值
						title: '', //标题查找
						type: '', //搜索方式:1:歌曲名称搜索;2:制作人搜索; title 为搜索内容
						page: this.page, //页数:默认1
						pageSize: this.pageSize,
						recommend: 7, //成品音乐1 海外是2 内地是7
					}).then((res) => {
						this.loading = false
						
						console.log(res, '制作人音乐列表成功');
						// this.DemoseaList = res.data.list
						this.numMusician = res.data.count
						if (this.page == 1) {
							let arr = []
							arr = res.data.list.map(item => {
						 	return {
									...item,
									titlecontent: '立即购买'
								}

							})
							this.DemoseaList = arr
						} else {
							let arr = []
							arr = res.data.list.map(item => {
								return {
									...item,
									titlecontent: '立即购买'
								}

							})
							this.DemoseaList = this.DemoseaList.concat(arr)
						}
						if(this.DemoseaList == ''){
							this.isEmpty = true
						}else {
							this.isEmpty = false
						}
						if (this.page == Math.ceil(this.numMusician / this.pageSize)) {
							setTimeout(() => {
								this.nothing = true
							}, 1000)

						}
						// this.numMusician = res.data.count
						// console.log(res.data.count,'res.data.list.count')
					})
					.catch((err) => {
						console.log(err, '制作人音乐列表失败');
					});
			}

		}
	}
</script>

<style lang="less" scoped>
	/deep/ .el-loading-mask{
		background-color: rgba(0,0,0,0);
	}
	.main {
		width: 100%;
		.emptyView{
			width: 100%;
			display: flex;
			justify-content: center;
		}
		.more-wrap {
			display: flex;
			width: 100%;
			justify-content: center;
			margin-top: 20px;
		
			.more {
				width: 190px;
				height: 36px;
				border-radius: 6px;
				border: 1px solid @recommendMusicianListTextColor;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
		
				.more-text {
					font-size: 14px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: @recommendMusicianListTextColor;
					line-height: 20px;
					margin-left: 10px;
				}
			}
			.nothing-text{
				color: @recommendMusicianListTitleColor;
			}
		}
	}
</style>
